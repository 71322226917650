.cart-switcher {
    &.side-panel {
        .action-bar {
            background-color: var(--white);
            display: flex;
            flex-wrap: wrap;
            justify-content: end;

            .action-bar__button {
                border-right: none;
                border-bottom: none;
                border-left: 1px solid var(--main-color);
            }
        }
    }

    .cart-switcher__main {
        height: calc(100% - 4rem);
        padding: 0;
    }

    .cart-switcher__body {
        height: 100%;
        margin-top: 0;
        padding: 0;
    }

    .side-panel__inner {
        max-width: 48.4rem;
    }

    .wrapper {
        // min-height: calc(100vh - 4rem - 15.2rem);
        width: 100%;

        .heading {
            position: sticky;
            top: 0;
            padding: 2.2rem 0;
            margin-bottom: 0.9rem;
            z-index: 1;
            background-color: var(--white);

            p {
                font-size: 1.4rem;
                line-height: 1.71;
                color: var(--black);
            }

            h2 {
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 2.4rem;
                color: var(--main-color);
                line-height: 1.17;
                margin-bottom: 0.8rem;
                margin-top: 0;
            }
        }

        .cart-box {
            border: 1px solid var(--light-grey);
            margin-bottom: 1.2rem;

            &:last-child {
                margin-bottom: 0;
            }

            &.active-box {
                border: 1px solid var(--main-color);
            }
            .cart-box-products {
                cursor: pointer;
                .minicart-container{
                    flex-direction: column;

                    .section-container-general-product-bag{
                        .section-product-info-mini-bag {
                            .product-title {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 12rem;
                                font-size: 1.4rem;
                                margin-top: 0;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
                .section-container-general-product-bag{
                    padding: 1.25rem 1.45rem;
                    pointer-events: none;
                    border-bottom: 1px solid var(--light-grey);

                    .section-product-img-mini-bag {
                        width: 6rem;
                        height: 9rem;
                        flex: none;
                        .product-img-mini-bag {
                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    .section-product-info-mini-bag {
                        width: 100%;
                        padding-left: 1.2rem;
                        position: relative;
                        padding: 0;
                        padding-left: 1.8rem;

                        p, span {
                            font-size: 1.4rem;
                            line-height: 1;
                            color: var(--black);
                        }

                        p {
                            margin: 0;
                            line-height: 1.17;
                            font-size: 1.2rem;
                            margin-top: 0.8rem;
                        }

                        .value {
                            &.discounted {
                                margin: 0;
                            }
                        }
                        .strike-through{
                            .value {
                                margin: 0;
                            }
                        }

                        .conrainer-info-pronduct-mini-bag{
                            width: 55%;

                            .name-pronduct-mini-bag{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-top: 0;
                                margin-bottom: 1rem;
                                font-size: 1.4rem;
                            }
                        }

                        .conrainer-price-pronduct-mini-bag {
                            text-align: end;
                        }

                        .info-pronduct-mini-bag {
                            font-size: 1.2rem;

                            &.price-basic-small {
                                margin-bottom: 1.2rem;
                            }

                            .non-adjusted-price,
                            .product-tile-body__price {
                                display: flex;
                                flex-direction: column-reverse;
                                gap: 0.2rem;

                                .discount-item {
                                    display: none;
                                }

                                .product-total-discount {
                                    display: none;
                                }

                                span {
                                    line-height: 1;
                                }

                                .price-info-omnibus {
                                  display: none;
                                }

                            }

                            .sales {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }

            .cart-box-radio {
                position: relative;

                label {
                    color: var(--black);
                }

                input[type="radio"] {
                    opacity: 0;
                    position: absolute;
                    top: 1.5rem;
                    left: 1.7rem;
            
                    & ~ label {
                        cursor: pointer;
                        position: relative;
                        padding-left: 5.73rem;
                        font-size: 1.8rem;
                        min-height: 4.3rem;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid var(--light-grey);
            
                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 1.4rem;
                            transform: translateY(-50%);
                            background-color: var(--white);
                            border: 0.1rem solid var(--black);
                            border-radius: 50%;
                            width: 1.8rem;
                            height: 1.8rem;
                        }
                    }
            
                    &:checked ~ label {
                        &::after {
                            content: '';
                            width: 1.2rem;
                            height: 1.2rem;
                            position: absolute;
                            top: 50%;
                            left: 1.7rem;
                            transform: translateY(-50%);
                            background-color: var(--black);
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    .cart-switcher-component {
        min-height: 100%;
    }

    .cart-box-footer {
        background-color: var(--white);
        position: sticky;
        bottom: 0;
        padding: 2.2rem 2rem;

        p {
            color: var(--black);
        }
    }
}
