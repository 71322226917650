// @import url('https://use.typekit.net/shj5uhm.css');
@mixin primaryFont($weight: 500) {
  font-family: BananaGrotesk, sans-serif;
  font-weight: $weight;
  font-style: normal;
}
@mixin secondaryFont() {
  font-family: franklin-gothic-compressed, sans-serif;
  font-weight: 600;
  font-style: normal;
}
// @font-face {
//   font-family: 'BananaGrotesk';
//   src: url('../fonts/BananaGrotesk-Light.woff2') format('woff2'), url('../fonts/BananaGrotesk-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'BananaGrotesk';
//   src: url('../fonts/BananaGrotesk-Medium.woff2') format('woff2'), url('../fonts/BananaGrotesk-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'BananaGrotesk';
//   src: url('../fonts/BananaGrotesk-Bold.woff2') format('woff2'), url('../fonts/BananaGrotesk-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
// }

[data-whatintent='mouse'] *:focus {
  outline: none;
}