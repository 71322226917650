.row-span-1 {
  grid-row: span 1;
}

.row-span-2 {
  grid-row: span 2;
}

.row-span-3 {
  grid-row: span 3;
}

.row-span-4 {
  grid-row: span 4;
}

.col-span-1 {
  grid-column: span 1;
  overflow: hidden;
}

.col-span-2 {
  grid-column: span 2;
}

.col-span-3 {
  grid-column: span 3;
}

.col-span-4 {
  grid-column: span 4;
}

.category-block {
  border-top: solid 1px var(--main-color);
  padding: 60px 0;

  &:nth-child(1) {
    border-top: none;
  }

  .product-grid .product {
    border-top: solid 1px var(--main-color);
    margin-top: -1px;
  }

  h2 {
    margin: 0;
    max-width: fit-content;
    border: solid 1px var(--main-color);
    border-bottom: none;
    font-size: 3.2rem;
    line-height: 3.2rem;
    padding: 4px 8px;

    a {
      @include secondaryFont();
      text-decoration: none;
    }

    svg {
      padding-top: 3px;
      height: 2.8rem;
      width: 2.8rem;
    }
  }

  .show-more {
    margin-top: 40px;
    text-align: center;
  }
}

.product-grid {
  .content-asset,
  .product {
    height: 100%;
  }
}

.fullbleed-banner-asset {
  width: 100%;
  position: relative;

  .background-img {
    width: 100%;
  }

  .content-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-content {
    width: calc(100% - 4rem);
    background-color: white;
    padding: 1.6rem;
    outline: 1px solid red;

    .title {
      margin: 0;
      font-size: 3.6rem;
      letter-spacing: .2px;
    }

    .text {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: .2px;
      margin-block: 1.6rem;
    }
  }

  &.type2 .main-content .title {
    font-family: BananaGrotesk, serif;
    font-size: 1.8rem;
  }
}