@import './typography';
@import 'components/plp/plp';
@import 'components/cart-switcher';

@media (min-width: 992px)  {
  @import 'components/cart-switcher.lg';
  @import 'components/plp/plp.lg';
}

.product-grid {
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  border-left: 1px solid var(--main-color);

  .product {
    border-bottom: 1px solid var(--main-color);
    border-right: 1px solid var(--main-color);
    width: 100%;
    height: 100%;
  }
  & + .bottom-asset {
    margin-top: 40px;
  }
}
.back-to-top-box {
  width: 0;
  height: 0;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  position: fixed;
  right: 0;
  bottom: 12rem;
  transform: rotate(90deg);
  z-index: 2;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
  padding: 0;
  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: var(--white);
  }
  &.enable {
    height: 4rem;
    width: 4rem;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.product-grid {
  .gridCarouselSpan {
    .content-asset {
      width: 100%;
      overflow: auto;
    }
    grid-column: span 2;
  }
}
@media (min-width: 992px) {
  .product-grid {
    .gridCarouselSpan {
      grid-column: span 4;
    }
  }
}
