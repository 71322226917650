.cart-switcher {
  .wrapper {
    padding: 2.2rem 3.3rem;

    .cart-box {
      .cart-box-products {
          .section-container-general-product-bag{
            padding: 1.25rem 5.8rem;
            .section-product-info-mini-bag{
              .conrainer-info-pronduct-mini-bag{
                width: 70%;
              }
            }
            
          }
      }
    }
  }
}
